import React from 'react'
import { Box, Typography } from '@mui/material'
import Develop from '../Assets/Images/underdevelopp.jpg'
import Footer from '../Layouts/Footer'
import CareerHeader from '../Layouts/CareerHeader'

const UnderDevelop = () => {
  return (
    <>
<CareerHeader/>

<Box sx={{display:'flex',
justifyContent:'center',
flexDirection:'column',
alignItems:'center',
minHeight:'100vh',
color:'white',
backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 30.2%, rgb(0,0, 0, 0.8) 90.9%), url(${Develop})`,
                backgroundPosition: "center",
                backgroundSize: "cover",


}}>
<h1>
Coming Soon
</h1>

<Typography

          sx={{
textAlign:'center',
            '@media(max-width:600px)': {
      paddingLeft: '4%',
      paddingRight:'4%'
    },

    '@media(min-width:600px) and (max-width:900px)': {
      paddingLeft: '8%',
      paddingRight:'8%'
    },
          }}

>
    This Page is Under Development, Contact Us for Update Now
</Typography>
</Box>

<Footer/>


    </>
  )
}

export default UnderDevelop